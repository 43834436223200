import {Router} from 'vue-router'
import axios from 'axios'
import {OrganizationStore} from '@/stores/organization.store'
import * as Sentry from '@sentry/vue'
import GlobalUtils from '@/utils/global.utils'


export class ErrorUtils {

  static captureError(error: any): void {
    console.error(error)

    /**
     * Axios errors get handled by the API interceptors, so we don't log them twice
     */
    if (GlobalUtils.sentryEnabled() && !axios.isAxiosError(error)) {
      Sentry.captureException(error)
    }
  }

  /**
   * @returns true if the error was handled successfully
   */
  static async handleWrongRegionError(error: any, router: Router, os: OrganizationStore): Promise<boolean> {
    if (!axios.isAxiosError(error)) {
      return false
    }

    if (![403, 406].includes(error?.response?.status ?? -1)) {
      return false
    }

    os.configStatus = 'error'
    console.error('User doesn\'t have region access', error)
    await router.push({
      name: 'region-error',
      params: {
        region: error.response?.data?.detail?.region ?? 'uk',
      },
    })

    return true
  }
}