interface DialogProps {
  modal: any,
  position: string,
  contentClass: {
    backdrop: boolean,
    collapsed: boolean
  },
}
export default {
  root: ({ state }: any) => ({
    class: [
      'tw-max-h-[95vh]',
      // Padding, margin
      'tw-p-4',
      // Shape
      'tw-rounded-lg',
      'tw-shadow-lg',
      'tw-border-0',
      // Size
      'tw-max-h-[90vh]',
      'tw-m-0',
      // Color
      'tw-bg-white',
      'tw-dark:border',
      'tw-dark:border-surface-700',
      // Transitions
      'tw-transform',
      'tw-scale-100',
      // Maximized State
      {
        'tw-transition-none': state.maximized,
        'tw-transform-none': state.maximized,
        '!tw-w-screen': state.maximized,
        '!tw-h-screen': state.maximized,
        '!tw-max-h-full': state.maximized,
        '!tw-top-0': state.maximized,
        '!tw-left-0': state.maximized,
      },
    ],
  }),
  pcCloseButton: {
    class: ['-tw-mt-4'],
  },
  header: {
    class: [
      // Flexbox and Alignment
      'tw-flex tw-items-center tw-justify-between',
      'tw-shrink-0',
      // Shape
      'tw-border-t-0',
      'tw-rounded-tl-lg',
      'tw-rounded-tr-lg',
      // Colors
      'tw-bg-surface-0 tw-dark:bg-surface-800',
      'tw-text-surface-700 tw-dark:text-surface-0/80',
    ],
  },
  title: {
    class: ['tw-font-bold tw-text-lg'],
  },
  headerActions: {
    class: ['tw-flex tw-items-center -tw-mt-2'],
  },
  content: ({ state, instance }: any) => ({
    class: [
      // Shape
      {
        grow: state.maximized,
        'tw-rounded-bl-lg': !instance.$slots.footer,
        'tw-rounded-br-lg': !instance.$slots.footer,
      },
      // Colors
      // 'tw-bg-surface-0 tw-dark:bg-surface-800',
      // 'tw=text-surface-700 tw-dark:text-surface-0/80',
      // Misc
      'tw-overflow-y-auto',
      'tw-overflow-x-hidden',
    ],
  }),
  footer: {
    class: [
      // Flexbox and Alignment
      'tw-flex items-center tw-justify-end',
      'tw-shrink-0',
      'tw-text-right',
      'tw-gap-2',
      // Spacing
      'tw-pb-6',
      // Shape
      'tw-border-t-0',
      'tw-rounded-b-lg',
      // Colors
      'tw-bg-surface-0 tw-dark:bg-surface-800',
      'tw-text-surface-700 tw-dark:text-surface-0/80',
    ],
  },
  mask: ({ props }: { props: DialogProps }) => {
    return ({
      class: [
        'tw-z-[1500]',
        // Transitions
        'tw-transition-all',
        'tw-duration-300',
        { 'tw-p-5': !(props.position === 'full') },
        { 'navbar-mask': props.contentClass?.backdrop },
        { 'collapsed': props.contentClass?.collapsed},
        // Background and Effects
        { 'tw-bg-black/10': props.modal, 'tw-has-[.mask-active] tw-backdrop-blur-[1px]': props.modal },
      ],
    })
  },
  transition: ({ props }: any) => {
    if (props.position === 'top') {
      return {
        enterFromClass: 'tw-opacity-0 tw-scale-75 tw-translate-x-0 -tw-translate-y-full tw-translate-z-0 tw-mask-active',
        enterActiveClass: 'tw-transition-all tw-duration-200 ease-out',
        leaveActiveClass: 'tw-transition-all tw-duration-200 ease-out',
        leaveToClass: 'tw-opacity-0 tw-scale-75 tw-translate-x-0 -tw-translate-y-full tw-translate-z-0 tw-mask-active',
      }
    } else if (props.position === 'bottom') {
      return {
        enterFromClass: 'tw-opacity-0 tw-scale-75 tw-translate-y-full tw-mask-active',
        enterActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveToClass: 'tw-opacity-0 tw-scale-75 tw-translate-x-0 tw-translate-y-full tw-translate-z-0 tw-mask-active',
      } 
    } else if (props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft') {
      return {
        enterFromClass: 'tw-opacity-0 tw-scale-75 -tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
        enterActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveToClass: 'tw-opacity-0 tw-scale-75  -tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
      }
    } else if (props.position === 'right' || props.position === 'topright' || props.position === 'bottomright') {
      return {
        enterFromClass: 'tw-opacity-0 tw-scale-75 tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
        enterActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveToClass: 'tw-opacity-0 tw-scale-75 tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
      }
    } else {
      return {
        enterFromClass: 'tw-opacity-0 tw-scale-75 tw-mask-active',
        enterActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveActiveClass: 'tw-transition-all tw-duration-200 tw-ease-out',
        leaveToClass: 'tw-opacity-0 tw-scale-75 tw-mask-active',
      }
    }
  },
}