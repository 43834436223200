
function buttonStyling(options: any) {
  const baseClass = 'tw-border-solid tw-border-2 tw-text-base tw-rounded tw-font-semibold tw-py-2 tw-px-4'

  if (options.attrs.outlined) {
    return `${baseClass} tw-border-indigo-600`
  }

  if (options.attrs.variant === 'light') {
    return `${baseClass}`
  } else if (options.attrs.variant === 'secondary') {
    return `${baseClass}`
  } else if (options.attrs.variant === 'primary') {
    return baseClass + ' tw-button-dodona tw-border-transparent tw-text-white'
  }

  return 'tw-bg-teal-500 hover:bg-teal-700 tw-cursor-pointer tw-text-white tw-p-3 tw-border-round tw-border-none tw-flex tw-gap-2'
}

export default {
  root: (options: any) => ({
    root: buttonStyling(options),
    secondary: 'tw-red-teal-500 hover:tw-bg-red-700',
    label: 'tw-text-white tw-font-bold tw-text-xl', // OR { class: 'text-white font-bold text-xl' }
    icon: 'tw-text-white tw-text-2xl',
  }),
}