import { BvToast, BvToastOptions } from 'bootstrap-vue/src/components/toast'

export default class DodonaToast {
  private toastModal: BvToast | undefined

  setToastInstance(toast: BvToast) {
    this.toastModal = toast
  } 

  toast(message: string, options?: BvToastOptions): void {
    if (this.toastModal !== undefined) {
      this.toastModal.toast(message, {
        toaster: 'b-toaster-bottom-right',
        ...options,
      })
    }
  }
}
