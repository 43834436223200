import { MapRendererStyleOptions, RendererStyleProperties, SetRendererStyleOptions } from '@/libs/renderer/renderers'
import { LayerRole, UserRole } from '@/auth/roles'
import DodonaBackend from '@/libs/loaders/dodona-backend/api-client'
import { MinMax } from '@/types/app'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { customUploadMarkerIcon } from '@/utils/sharedComponents.utils'
import { mapOverlayZIndex } from '@/utils/layers/layerStyles.utils'
import { GeometryTypes } from '@/utils/layers/layout-vector-config'

interface DatasetDataOptions {
  name: string
  dataset: string
  genericApi?: boolean
  description?: string
  group?: string
  version?: string
  minZoom?: number
  role?: UserRole
  filterProperties?: PropertyFilter[]
}

export interface PropertyFilter {
  property_name: string
  filter: string[]
}


export abstract class DatasetData {
  public name: string
  public dataset: string
  public genericApi: boolean
  public description?: string
  public group?: string
  public version?: string
  public minZoom?: number
  public role?: UserRole
  public filterProperties: PropertyFilter[]

  protected constructor({
    name,
    dataset,
    description,
    group,
    version,
    minZoom,
    role,
    genericApi,
    filterProperties,
  }: DatasetDataOptions) {
    this.name = name
    this.dataset = dataset
    this.description = description
    this.group = group
    this.version = version
    this.minZoom = minZoom
    this.role = role
    this.genericApi = genericApi ?? false
    this.filterProperties = filterProperties ?? []
  }
  
  abstract getTag(): string
  
  isItemEnabled(currentZoom: number) {
    return !(this?.minZoom && currentZoom <= this?.minZoom)
  }
  
  getUniqueId(chunk: string|number = 0) {
    return this.name + '/' + chunk
  }

  updateFilter(filterProperties: PropertyFilter[]) {
    this.filterProperties = filterProperties
  }

  async fetchData(
    api: DodonaBackend,
    geometry: string,
    clipByRegionId?: number,
  ): Promise<FeatureCollection> {
    if (this.genericApi) {
      return await api.getGenericDataset(this.dataset, geometry, true, clipByRegionId)
    }

    return await api.dataset(this.dataset, geometry, this.version, clipByRegionId, this.filterProperties)
  }
}


export abstract class PolygonData extends DatasetData {
  rendererProperties: RendererStyleProperties
  mapStyle: MapRendererStyleOptions
  options: ClickableOptions | SimpleOption
  disableMinMax = false
  mapTypeStyle?: google.maps.MapTypeStyle[]
  paintStyleOptions?: Record<string, PolygonPaintStyle>

  protected constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    minZoom?: number,
    version?: string,
    options: ClickableOptions | SimpleOption,
    paintStyle: PolygonPaintStyle,
    filterProperties?: PropertyFilter[],
    disableMinMax?: boolean,
    mapTypeStyle?: google.maps.MapTypeStyle[]
    genericApi?: boolean,
    role?: UserRole,
    paintStyleOptions?: Record<string, PolygonPaintStyle>
  }) {
    super(args)

    if (args.disableMinMax) {
      this.disableMinMax = true
    }
    this.mapTypeStyle = args.mapTypeStyle
    this.paintStyleOptions = args.paintStyleOptions
    this.options = args.options
    if (this.options instanceof ClickableOptions) {
      if (this.options.subOptions.length > 0) {
        this.selectOption(this.options.subOptions[0].name)
      } else {
        throw new Error('there arent any options defined')
      }
    }

    this.rendererProperties = args.paintStyle.rendererProp
    this.mapStyle = args.paintStyle.mapStyle
  }

  switchPolygonStyle(type: string) {
    const styles = this.paintStyleOptions && this.paintStyleOptions[type]
    if (!styles) {
      return
    }

    this.rendererProperties = styles.rendererProp
    this.mapStyle = styles.mapStyle
  }

  hasOptions(): boolean {
    return this.options instanceof ClickableOptions
  }

  selectOption(name: string) {
    if (this.options instanceof ClickableOptions) {
      this.options.selectOption(name)
    }
  }

  getSelectedOption() {
    if (this.options instanceof ClickableOptions) {
      return this.options.getSelectedOption()
    }
    throw new Error('object has only one option')
  }


  getOnlyOption(): SimpleOption {
    if (this.options instanceof SimpleOption) {
      return this.options
    }
    throw new Error('object has multiple options')
  }

  getFeatureProperty(): string {
    return this.hasOptions() ?
      this.getSelectedOption().featureProperty :
      this.getOnlyOption().featureProperty
  }

  /**
   * sets the feature property to the style. master stylist needs to be aware of this,
   * so it needs to happen before we do the rendering
   */
  setFeaturePropertyToStyle(): void {
    if (this.mapStyle.params) {
      this.mapStyle.params.featureProperty = this.getFeatureProperty()
    }
  }

  getUniqueId(chunk = 0) {
    return this.hasOptions() ?
      this.name + '/' + this.getSelectedOption().name + '/' + chunk :
      this.name + chunk
  }

  /**
   * @param api Backend instance to make the request with
   * @param geometry GeoJSON geometry in WKT format
   * @param clipByRegionId Region id to clip by, used for workspace clipping
   */
  async getMinMax(api: DodonaBackend, geometry: string, clipByRegionId?: number): Promise<MinMax> {
    if (this.genericApi) {
      return await api.getGenericApiMinMax(this.dataset, geometry, this.getFeatureProperty(), false)
    }

    return await api.minMax(this.dataset, geometry, clipByRegionId, false, this.getFeatureProperty())
  }

  setMinMax({ min, max }: MinMax) {
    if (!this.mapStyle.params) {
      return
    }

    this.mapStyle.params.min = min
    this.mapStyle.params.max = max
  }
}


export class PoiClickableList {
  pois: PoiCheckboxData[]
  selectedPois: PoiCheckboxData[]
  selectedPoisName: string
  name: string
  group?: string
  icon?: string
  minZoom?: number
  role?: UserRole


  public constructor(private args: {
    pois: PoiCheckboxData[],
    defaultSelection: 'first' | 'all',
    group?: string,
    name: string,
    icon?: string,
    minZoom?: number,
    role?: UserRole
  }) {
    this.pois = args.pois
    this.group = args.group
    this.name = args.name
    this.icon = args.icon
    this.minZoom = args.minZoom
    this.role = args.role

    if (args.defaultSelection === 'first') {
      this.selectedPois = [this.pois[0]]
      this.selectedPoisName = this.pois[0].name
    } else {
      this.selectedPois = this.pois.slice()
      this.selectedPoisName = 'All'
    }
  }

  isItemEnabled(currentZoom: number) {
    return !(this?.minZoom && currentZoom <= this?.minZoom)
  }

  selectOption(name: string) {
    if (name === 'All') {
      this.selectedPois = this.pois
    } else {
      this.selectedPois = this.pois.filter(poi => poi.name === name)
    }

    this.selectedPoisName = name
  }
}


export class PoiCheckboxData extends DatasetData {
  enableClusters: boolean
  icon?: string | google.maps.Icon
  uiIcon?: string | google.maps.Icon
  includeLabel?: boolean

  public constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    version?: string,
    minZoom?: number,
    icon?: string,
    uiIcon?: string,
    enableClusters?: boolean
    genericApi?: boolean
    appRole?: UserRole
    includeLabel?: boolean
    filterProperties?: PropertyFilter[]
  }) {
    super({ ...args, genericApi: args.genericApi ?? true })
    this.enableClusters = args.enableClusters ?? false
    this.icon = args.icon
    this.uiIcon = args.uiIcon
    this.includeLabel = args.includeLabel ?? true
  }

  getTag(): string {
    return 'ui-poi'
  }
}

export class LayerCircleData extends DatasetData {
  setStyle: SetRendererStyleOptions
  mapStyle: MapRendererStyleOptions
  private readonly featureProperty: string

  constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    version?: string,
    minZoom?: number,
    paintStyle: CirclePaintStyle
    featureProperty: string
    role?: LayerRole
  }) {
    super(args)
    this.setStyle = args.paintStyle.setStyle
    this.mapStyle = args.paintStyle.mapStyle
    this.featureProperty = args.featureProperty
  }

  getFeatureProperty(): string {
    return this.featureProperty
  }

  setFeaturePropertyToStyle(): void {
    if (this.mapStyle.params) {
      this.mapStyle.params.featureProperty = this.getFeatureProperty()
    }
  }

  getTag(): string {
    return 'ui-layer-tab'
  }
}

export const parkingOperators = new Map([
  [2856, 'Municipal'],
  [2857, 'Customer convenience'],
  [2858, 'Commercial'],
])

export class ParkingPolygonData extends PolygonData {
  tooltips: (Tooltip & { property: string })[]

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ClickableOptions | SimpleOption;
    paintStyle: PolygonPaintStyle;
    disableMinMax?: boolean;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    filterProperties: PropertyFilter[];
    genericApi?: boolean;
    role?: LayerRole;
    tooltips?: (Tooltip & { property: string })[];
  }) {
    super(args)
    this.tooltips = args.tooltips ?? []
  }

  getTag(): string {
    return 'ui-parking'
  }

  hasOptions() {
    return false
  }

  getUniqueId() {
    return 'parking-data'
  }
}

export class InfillLsoaPolygonData extends PolygonData {
  tooltips: (Tooltip & { property: string })[]

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ClickableOptions | SimpleOption;
    paintStyle: PolygonPaintStyle;
    disableMinMax?: boolean;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    genericApi?: boolean
    role?: LayerRole
    tooltips?: (Tooltip & { property: string })[]
  }) {
    super(args)
    this.tooltips = args.tooltips ?? []
  }

  getTag(): string {
    return 'ui-infill-lsoa'
  }

  hasOptions() {
    return false
  }

  getUniqueId() {
    return 'infill-lsoa-data'
  }
}

export enum StylingDatasetData {
  poi,
  polygon,
  line
}


export class GenericRuntimeDecisionData extends DatasetData {
  color: string
  label: string
  tag: string
  render_with_deck_gl: boolean
  content_type: GeometryTypes | undefined
  customTooltip: boolean

  public constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    version?: string,
    minZoom?: number,
    role?: UserRole
    includeLabel?: boolean
    filterProperties?: PropertyFilter[]
    color: string,
    label: string,
    tag: string
    render_with_deck_gl: boolean,
    content_type: GeometryTypes | undefined
    customTooltip?: boolean, 
  }) {
    super({
      name: args.name,
      dataset: args.dataset,
      description: args.description,
      group: args.group,
      version: args.version,
      minZoom: args.minZoom,
      role: args.role,
      genericApi: true,
      filterProperties: args.filterProperties,
    })

    this.color = args.color
    this.label = args.label
    this.render_with_deck_gl = args.render_with_deck_gl
    this.content_type = args.content_type
    this.tag = args.tag
    this.customTooltip = args.customTooltip ?? false
  }

  showCustomTooltip(): boolean {
    return this.customTooltip
  }


  exportAppropriateDatasetData(data: StylingDatasetData): DatasetData {
    const defaultStyle: PolygonPaintStyle = {
      rendererProp: {
        zIndex: mapOverlayZIndex.mapLayer,
        fillColor: this.color,
        strokeColor: '#15224a',
        strokeWeight: 1,
        strokeOpacity: 0.2,
      },
      mapStyle: {
        type: 'map',
        params: {
          map: {
            default: '1',
            styleProperty: 'fillOpacity',
          },
        },
      },
    }

    if (data === StylingDatasetData.poi) {
      return new PoiCheckboxData({
        name: this.name,
        dataset: this.dataset,
        description: this.description,
        group: this.group,
        version: this.version,
        minZoom: this.minZoom,
        genericApi: this.genericApi,
        filterProperties: this.filterProperties,
        appRole: this.role,
        enableClusters: true,
        icon: customUploadMarkerIcon(this.color, this.label || this.name.slice(0, 2)),
        includeLabel: true,
      })

    } else if (data === StylingDatasetData.line) {
      return new LayerPolygonData({
        name: this.name,
        group: 'Custom data',
        dataset: this.name,
        options: new SimpleOption('value'),
        minZoom: 15,
        paintStyle: {
          rendererProp: {
            zIndex: 7001,
            strokeColor: this.color,
            strokeWeight: 3,
          },
          mapStyle: {
            type: 'map',
          },
        },
        disableMinMax: false,
        genericApi: true,
        tooltips: [{ text: 'Name:', property: 'name' }, { text: 'Value:', property: 'value' }],
        customTooltip: this.showCustomTooltip(),
      })
    } else {
      return new LayerPolygonData({
        name: this.name,
        group: 'Custom data',
        dataset: this.name,
        options: new SimpleOption('value'),
        minZoom: 15,
        paintStyle: defaultStyle,
        paintStyleOptions: {
          'roadmap': defaultStyle,
          'hybrid': {
            rendererProp: {
              zIndex: mapOverlayZIndex.mapLayer,
              fillColor: this.color,
              strokeColor: '#ffffff',
              strokeWeight: 1,
              strokeOpacity: 1,
            },
            mapStyle: {
              type: 'map',
              params: {
                range: ['0.2', '0.4', '0.6', '0.8'],
                default: '0',
                styleProperty: 'fillOpacity',
              },
            },
          },
        },
        disableMinMax: false,
        genericApi: true,
        tooltips: [{ text: 'Name:', property: 'name' }, { text: 'Value:', property: 'value' }],
        customTooltip: this.showCustomTooltip(),
      })
    }

  }

  getTag(): string {
    return this.tag
  }
}


export class LayerPolygonData extends PolygonData {
  tooltips: (Tooltip & { property: string })[]
  prop: string | undefined
  private customTooltip: boolean

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ClickableOptions | SimpleOption;
    paintStyle: PolygonPaintStyle;
    disableMinMax?: boolean;
    prop?: string;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    genericApi?: boolean
    role?: LayerRole
    tooltips?: (Tooltip & { property: string })[],
    paintStyleOptions?: Record<string, PolygonPaintStyle>
    customTooltip?: boolean
  }) {
    super(args)
    this.tooltips = args.tooltips ?? []
    this.customTooltip = args.customTooltip ?? false
    if (args.prop) {
      this.prop = args.prop
    }
  }

  async getMinMax(api: DodonaBackend, geometry: string, clipByRegionId?: number): Promise<MinMax> {
    if (this.genericApi) {
      return await api.getGenericApiMinMax(this.dataset, geometry, this.getFeatureProperty(), false)
    }

    return await api.minMax(this.dataset, geometry, clipByRegionId, false, this.getFeatureProperty())
  }

  getTag(): string {
    return 'ui-layer-tab'
  }

  getTooltips() {
    return this.tooltips
  }

  showCustomTooltip(): boolean {
    return this.customTooltip
  }
}

export const smartPlanningPolygonDataTags = 'ui-smart-planning-tab'

export class SmartPlanningPolygonData extends LayerPolygonData {
  getTag(): string {
    return smartPlanningPolygonDataTags
  }
}

export class LayerCheckboxData extends PolygonData {
  icon: string | google.maps.Icon
  tooltips: (Tooltip & { property: string })[]

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ClickableOptions | SimpleOption;
    rendererProp: RendererStyleProperties | PolygonPaintStyle;
    disableMinMax?: boolean;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    genericApi?: boolean
    icon: string | google.maps.Icon,
    role?: UserRole
    tooltips?: (Tooltip & { property: string })[],
    filterProperties?: PropertyFilter[]
  }) {
    let paintStyle: PolygonPaintStyle

    if ((args.rendererProp as PolygonPaintStyle).mapStyle && (args.rendererProp as PolygonPaintStyle).rendererProp) {
      paintStyle = args.rendererProp as any
    } else {
      paintStyle= { rendererProp: args.rendererProp as RendererStyleProperties, mapStyle: { type: 'map' } }
    }

    super({
      ...args,
      paintStyle: paintStyle,
    })

    this.tooltips = args.tooltips ?? []
    this.icon = args.icon
  }

  getTag(): string {
    return 'ui-layer-checkbox'
  }

  getTooltips(): (Tooltip & { property: string })[] {
    return this.tooltips
  }
}

export abstract class LayerMultipleOptionCheckboxData extends DatasetData {
  // used for selecting correct index for array layer in rendering service
  abstract getOptionLayerIndex(feature: Feature<Geometry, GeoJsonProperties>): number;

  rendererProperties: RendererStyleProperties
  mapStyle: MapRendererStyleOptions
  // options: ClickableOptions | SimpleOption
  disableMinMax = false
  mapTypeStyle?: google.maps.MapTypeStyle[]
  paintStyleOptions?: Record<string, PolygonPaintStyle>
  icon: (string | google.maps.Icon)[]
  options: LayerCheckboxData[]
  tooltips: (Tooltip & { property: string })[]
  category: Record<string, boolean>
  optionValues: string[]

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: LayerCheckboxData[];
    optionValues: string[],
    disableMinMax?: boolean;
    rendererProp: RendererStyleProperties;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    tooltips: (Tooltip & { property: string })[];
    genericApi: boolean
    icon: (string | google.maps.Icon)[],
    role?: UserRole,
    paintStyle: PolygonPaintStyle,
    paintStyleOptions?: Record<string, PolygonPaintStyle>,
    filterProperties?: PropertyFilter[]
  }) {
    super(args)

    this.options = args.options
    this.icon = args.icon
    this.tooltips = args.tooltips ?? []
    this.category = args.options.reduce((acc, item) => {
      acc[item.name] = false
      return acc
    }, {} as Record<string, boolean>)

    if (this.icon.length === this.options.length) {
      this.options = this.options.map((k, i) => {
        k.icon = this.icon[i]
        return k
      })
    }
    this.optionValues = args.optionValues
    this.rendererProperties = args.paintStyle.rendererProp
    this.mapStyle = args.paintStyle.mapStyle

  }

  getTag(): string {
    return 'ui-layer-checkbox-multiple'
  }

  hasOptions() {
    return true
  }

  getTooltips() {
    return this.tooltips
  }

  enableOption(datasetName: string, checked: boolean) {
    this.category[datasetName] = checked
  }

  shouldShowOption(optionIndex: number): boolean {
    return this.category[this.options[optionIndex].name]
  }

  getFeatureProperty() {
    return 'multilayer-checkbox'
  }
}


export class LayerMultiColorData extends DatasetData {
  icon: string | google.maps.Icon
  options: ColoredClickableOption
  tooltips: (Tooltip & { property: string })[]

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ColoredClickableOption;
    disableMinMax?: boolean;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    tooltips: (Tooltip & { property: string })[];
    genericApi: boolean
    icon: string | google.maps.Icon,
    role?: UserRole
  }) {
    super(args)

    this.options = args.options
    this.icon = args.icon
    this.tooltips = args.tooltips ?? []

    this.selectOption(this.options.subOptions[0].name)
  }

  getTag(): string {
    return 'ui-layer-checkbox'
  }

  hasOptions() {
    return true
  }

  getFeatureProperty(): string {
    return this.options.getSelectedOption().featureProperty
  }

  selectOption(name: string) {
    this.options.selectOption(name)
  }

  setFeaturePropertyToStyle() {
    const mapStyle = this.options.getSelectedOption().paintStyle.mapStyle
    if (mapStyle.params) {
      mapStyle.params.featureProperty = this.getFeatureProperty()
    }
  }

  getUniqueId() {
    return this.hasOptions() ?
      this.name + '/' + this.options.getSelectedOption().name :
      this.name
  }

  getTooltips() {
    return this.tooltips
  }

}


export interface Tooltip {
  text: string
  property: string
  valueTransform?: (value: any, feature: google.maps.Data.Feature) => string
  rounded?: number

  /**
   * Additional CSS for the label element
   */
  labelStyle?: Partial<CSSStyleDeclaration>

  /**
   * Additional CSS for the value element
   */
  valueStyle?: Partial<CSSStyleDeclaration>
}

export class SimpleOption {
  featureProperty: string
  tooltip?: Tooltip

  constructor(featureProperty: string, tooltip?: Tooltip) {
    this.featureProperty = featureProperty
    this.tooltip = tooltip
  }
}

type SerializeFn = (s: Feature<Geometry, GeoJsonProperties>) => Feature<Geometry, GeoJsonProperties>
const toSerialize = (s: any) => s

// Used for adding multiple dropdown options to the feasibility
// selection type
export class LayerMultiOptionColorData extends DatasetData {
  serialize: SerializeFn
  icon: string | google.maps.Icon
  options: ColoredClickableOption[]
  tooltips: (Tooltip & { property: string })[]
  optionHeaders: string[] = []

  constructor(args: {
    name: string;
    dataset: string;
    description?: string;
    group?: string;
    minZoom?: number;
    version?: string;
    options: ColoredClickableOption[],
    disableMinMax?: boolean;
    mapTypeStyle?: google.maps.MapTypeStyle[];
    tooltips: (Tooltip & { property: string })[];
    genericApi: boolean
    icon: string | google.maps.Icon,
    role?: UserRole
    optionHeader: string[],
    serializer: SerializeFn,
  }) {
    super(args)

    this.optionHeaders = args.optionHeader
    this.options = args.options
    this.serialize = args.serializer || toSerialize
    this.icon = args.icon
    this.tooltips = args.tooltips ?? []

    this.options.forEach((option, index) => {
      this.selectOption(index, option.subOptions[0].name)
    })
  }

  getTag(): string {
    return 'ui-layer-checkbox'
  }

  getMultiTags(): string {
    return 'ui-layer-checkbox'
  }

  hasOptions() {
    return true
  }

  getAllKeys(): string[] {
    return this.optionHeaders
  }

  getSelectedValues() {
    return this.options.map((_, index) => this.options[index].getSelectedOption().value)
  }

  getUniqueIds(): string[] {
    return this.options.map((_, i) => this.getUniqueId(i))
  }

  getFeatureProperty(index: number): string {
    return this.options[index].getSelectedOption().featureProperty
  }

  selectOption(index: number, name: string | number) {
    // const optionName = this.serialize[index](name)
    this.options[index].selectOption(name)
  }

  setFeaturePropertyToStyle(index: number) {
    const mapStyle = this.options[index].getSelectedOption().paintStyle.mapStyle
    if (mapStyle.params) {
      mapStyle.params.featureProperty = this.getFeatureProperty(index)
    }
  }

  getUniqueId(index: number) {
    return this.hasOptions() ?
      this.name + '/' + this.options[index].getSelectedOption().value :
      this.name
  }

  getTooltips() {
    return this.tooltips
  }
}


export class ClickableOptions {
  name?: string
  subOptions: SubOption[]
  selectedOption?: SubOption

  constructor(name: string, subOptions: SubOption[]) {
    if (subOptions.length <= 1) {
      throw new Error('You need to provide at least 2 options')
    }
    this.name = name
    this.subOptions = subOptions
  }

  selectOption(name: string) {
    this.selectedOption = this.subOptions.find(subOption => subOption.name === name)
  }

  getSelectedOption(): SubOption {
    if (!this.selectedOption) {
      throw new Error('No option has been selected')
    }
    return this.selectedOption
  }
}


export class ColoredClickableOption {
  subOptions: ColoredSubOption[]
  selectedOption?: ColoredSubOption

  constructor(subOptions: ColoredSubOption[], private selectValue: ColoredSubOptionProperties = 'name') {
    if (subOptions.length <= 1) {
      throw new Error('You need to provide at least 2 options')
    }
    this.subOptions = subOptions
  }

  selectOption(name: string | number) {
    this.selectedOption = this.subOptions.find(subOption => subOption[this.selectValue] === name)
  }

  getSelectedOption(): ColoredSubOption {
    if (!this.selectedOption) {
      throw new Error('No option has been selected')
    }
    return this.selectedOption
  }
}


export interface ColoredSubOption {
  name: string
  featureProperty: string
  paintStyle: PolygonPaintStyle
  value: string | number
}

export type ColoredSubOptionProperties = keyof Omit<ColoredSubOption, 'paintStyle' | 'featureProperty'>

export interface SubOption {
  name: string
  featureProperty: string
}

export interface PolygonPaintStyle {
  rendererProp: RendererStyleProperties,
  mapStyle: MapRendererStyleOptions,
}

export interface CirclePaintStyle {
  setStyle: SetRendererStyleOptions,
  mapStyle: MapRendererStyleOptions,
}
