export enum AppRole {
  // Workspace features
  workspaceNotes = 'workspace/notes',
  workspaceLocking = 'workspace/locking',
  workspaceBulkEdit = 'workspace/bulk_edit',
  infill = 'workspace/infill',
  activeUtilizationData = 'workspace/active_utilization_data',
  signalChecker = 'workspace/signal_checker',
  pogoModelScore = 'workspace/pogo_model_score',
  overwriteSiteAssessmentScore = 'workspace/overwrite_site_assessment_score',
  landAnalysis = 'app/land-analysis',

  // Planning levels
  planningLevelLocal = 'workspace/planning_level/local',
  planningLevelCountry = 'workspace/planning_level/country',
  planningLevelStreetFleet = 'workspace/planning_level/streetfleet',

  // Data
  drivingCatchmentAreaMinutes = 'data/driving_catchment_area_minutes',
  evcsPremiumData = 'data/evcs_premium_data',
  eConnect = 'data/e_connect',
  parking = 'data/parking',
  premiumParkingData = 'data/premium_parking_data',
  evRegistrationForecast = 'data/ev_forecast',

  // App views
  portfolioView = 'views/portfolio',
  areaExplorer = 'views/area_explorer',

  // Other
  admin = 'app/admin',
  organizationAdmin = 'app/organization_admin',
  generateReports = 'app/reports',
  genericDatasetUpload = 'app/generic_dataset_upload',
  labelSet = 'app/label_set',

  // Site detail
  streetViewCanvas = 'site/street_view_canvas',
  siteReport = 'site/site_report',
  siteImageCapture = 'site/site_image_capture',
  siteImageCaptureAnnotate = 'site/site_image_capture_annotate',
  siteImagePublicLink = 'views/site_image_public_link',
}

export enum LayerRole {
  evCountByLa = 'layers/ev_count_by_la',

  //layers traffic data
  trafficDensity = 'layers/traffic_density',
  trafficFromNYStateDepartment = 'layers/traffic_from_ny_state_department',
  
  //layer other data
  predictedOffstreetParkingv211 = 'layers/predicted_offstreet_parking_v211',
  predictedOffstreetParkingv211b = 'layers/predicted_offstreet_parking_v211b',
  predictedOffstreetParkingv1 = 'layers/predicted_offstreet_parking_v1',
  predictedOffstreetParkingResidential = 'generic_dataset/offp_residential_uk',
  localAuthorityLayer = 'layers/local_authority_layer',
  poiClusteringV1 = 'layers/poi_clustering_v1',
  poiClusteringV2 = 'layers/poi_clustering_v2',
  onStreetHouseholdPercentage = 'layers/on_street_household_percentage',

  // accommodation type layers
  householdDensity = 'layers/household_density',
  pctFlat = 'layers/percentage_of_flats',
  pctDetached = 'layers/percentage_of_detached_houses',
  pctSemiDetached = 'layers/percentage_of_semi_detached_houses',
  pctTerraced = 'layers/percentage_of_terraced_houses',
}


export enum FeasibilityRole {
  // Street furniture
  telephoneCallBox = 'feasibility/telephone_call_boxes',
  ticketMachines = 'feasibility/ticket_machines',

  // Power network
  energeoSubstations = 'feasibility/energeo_substations',

  // Traffic regulation order
  zigZagLine = 'feasibility/zig_zag_line',
  doubleYellowLine = 'feasibility/double_yellow_line',
  parkingBay = 'feasibility/parking_bay',
  singleYellowLine = 'feasibility/single_yellow_line',
  appywayTRO = 'feasibility/appyway_tro',
  redLine = 'feasibility/red_line',

  // Restrictions
  pavementWidth = 'feasibility/pavement_width',

  showLocations = 'app/show_locations',
}

export type UserRole = AppRole | LayerRole | FeasibilityRole 


export enum Organization {
  dodona = 'dodona',
  streetFleet = 'street-fleet',
  pogo = 'pogo',
  eon = 'eon',
  chargy = 'chargy',
  chargeMyStreet = 'charge_my_street',
  connectedKerb = 'ck',
  connectedKerbUs = 'ck_us',
  trojan = 'trojan',
  beev = 'beev',
  plusCharge = 'plus-charge',
  urbanFox = 'urban-fox',
  sms = 'sms',
}
