import { TinyEmitter } from 'tiny-emitter'

export type DodonaGlobalEvents =
  | 'map'
  | 'layer-set'
  | 'layer-removed'
  | 'generate-sites'
  | 'infill-sites'
  | 'workspace-site-markers'
  | 'update-sites-toolbox-sites-status'
  | 'map-click'
  | 'workspace-refresh'
  // When a site is updated, moved, deleted or added
  | 'site-changed'
  | 'empty-features'
  | 'change-left-tab'

export type ExternalGlobalEvents =
  | 'bv::dropdown::hide'
  | 'bv::dropdown::show'
  | 'bv::tooltip::show'
  | 'bv::tooltip::hide'
  | 'bv::modal::hide'

export type DodonaMapEvents = 
  | 'workspaceRegionShadow' 
  | 'workspaceSiteMoveId'
  | 'moveEventCatcherPolygon'

  export type DodonaModal = 'trenching-modal'
  export type AllGlobalEvents = DodonaGlobalEvents & ExternalGlobalEvents & DodonaModal
  

type EventBusArgs<T> = [T, ...any[]]

export const newEventBus = <T>() => {
  const localEmitter = new TinyEmitter()

  return {
    $on: (...args: EventBusArgs<T>) =>
      localEmitter.on.apply(localEmitter, args as any),
    $once: (...args: EventBusArgs<T>) =>
      localEmitter.once.apply(localEmitter, args as any),
    $off: (...args: EventBusArgs<T>) =>
      localEmitter.off.apply(localEmitter, args as any),
    $emit: (...args: EventBusArgs<T>) =>
      localEmitter.emit.apply(localEmitter, args as any),
  }
}

const globalEmitter = newEventBus<AllGlobalEvents>()

export default globalEmitter
