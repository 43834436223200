import { CirclePaintStyle, PolygonPaintStyle } from '@/utils/layers/layout-config'
import { RendererStyleProperties } from '@/libs/renderer/renderers'
import { AllEvOptions } from './layers-uk'


export const mapOverlayZIndex = {
  mapCoverageStyle: 5003,
  mapWorkspaceShadow: 5002,
  mapWorkspaceRegionPolygon: 5002,
  mapWorkspaceWards: 5001,
  mapWorkspaceLocalFactory: 5004,
  mapWorkspaceFreehold: 5005,
  mapWorkspaceLeasehold: 5006,
  mapLayer: 5100,
  mapLayerHover: 5101,
  mapLayerSelected: 5102,
  mapCatchmentLayer: 5103,
  mapLayerCatchmentHover: 5104,
  tooltips: 5100,
  customOverlays: 5101,
} 

export const INFILL_LSOA_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeColor: '#00B2FF',
    strokeOpacity: 1,
    strokeWeight: 4,
    fillOpacity: 0,
  },
  mapStyle: {
    type: 'map',
  },
}

export const INFILL_LSOA_WHITE_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#ffffff',
    strokeWeight: 4,
    strokeOpacity: 1,
  },
  mapStyle: {
    type: 'map',
  },
}

export const PARKING_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#15224a',
    strokeWeight: 1,
    strokeOpacity: 0.2,
  },
  mapStyle: {
    type: 'map',
    params: {
      scale: 'logarithmic',
      range: ['0.2', '0.4', '0.6', '0.8'],
      default: '0',
      styleProperty: 'fillOpacity',
    },
  },
}

export const TRAFFIC_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: 7000,
    strokeWeight: 3,
  },
  mapStyle: {
    type: 'map',
    params:
      {
        range: ['#E6DD8E', '#E6B060', '#E67860', '#C76FF0', '#0074FD'],
        scale: 'logarithmic',
        default: '#6D6D6D',
        styleProperty: 'strokeColor',
      },
  },
}

export const PARCEL_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    fillOpacity: 0.2,
    strokeColor: '#000000',
    strokeWeight: 1,
    strokeOpacity: 0.6,

  },
  mapStyle: {
    type: 'map',
  },
}

export function trafficRegulationOrderDefaultStyle(strokeColor: string): RendererStyleProperties {
  return {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeWeight: 1,
    strokeOpacity: 1,
    fillOpacity: 0,
    strokeColor: strokeColor,
  }
}

export function localFactorDefaultStyle(strokeColor: string, opacity: number = 1): RendererStyleProperties {
  return {
    zIndex: mapOverlayZIndex.mapWorkspaceLocalFactory,
    strokeWeight: 1,
    strokeOpacity: 1,
    fillOpacity: opacity,
    strokeColor: strokeColor,
    fillColor: strokeColor,
  }
}


export const FREEHOLD_STYLE: RendererStyleProperties = {
  zIndex: mapOverlayZIndex.mapWorkspaceFreehold,
  strokeWeight: 1,
  strokeOpacity: 1,
  fillOpacity: 0.44,
  fillColor: '#EDAD06',
  strokeColor: '#fff',
}

export const LEASEHOLD_STYLE: RendererStyleProperties = {
  //Absolute leasehold title
  zIndex: mapOverlayZIndex.mapWorkspaceLeasehold,
  strokeWeight: 1.5,
  strokeOpacity: 1,
  fillOpacity: 0.44,
  fillColor: '#00FF38',
  strokeColor: '#fff',
}


export const LAND_ANALYSIS_STYLE_SELECTED: RendererStyleProperties = {
  zIndex: mapOverlayZIndex.mapLayerSelected,
  fillOpacity: 0.8,
}


export const PARKING_BAY_STYLE: RendererStyleProperties = {
  zIndex: mapOverlayZIndex.mapLayer,
  strokeWeight: 1.5,
  strokeOpacity: 1,
  fillOpacity: 0,
  fillColor: '#297FFF',
  strokeColor: '#297FFF',
}


export const RES_REQ_CIRCLE: CirclePaintStyle = {
  setStyle: {
    type: 'set',
    params:
      {
        radius: 80,
        zIndex: 3000,
        fillOpacity: 0.9,
        strokeColor: '#000000',
        strokeWeight: 0.7,
      },
  },
  mapStyle: {
    type: 'map',
    params:
      {
        map:
          {
            owns_ev: '#2bff20',
            plans_to_buy: '#ffc82d',
            does_not_plan_to_buy: '#ff1414',
          },
        styleProperty: 'fillColor',
      },
  },
}

export const TRAFFIC_CIRCLE: CirclePaintStyle = {
  setStyle: {
    type: 'set',
    params:
      {
        zIndex: mapOverlayZIndex.mapLayer,
        fillColor: '#d53c57',
        fillOpacity: .7,
        strokeColor: '#000000',
        strokeWeight: 1,
      },
  },
  mapStyle: {
    type: 'map',
    params:
      {
        lower: 20,
        upper: 80,
        styleProperty: 'radius',
        featureProperty: 'vehicle_count',
      },
  },
}

export const LOGARITHMIC_BLUE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#15224a',
    strokeWeight: 1,
    strokeOpacity: 0.2,
  },
  mapStyle: {
    type: 'map',
    params: {
      scale: 'logarithmic',
      range: ['0.2', '0.4', '0.6', '0.8'],
      default: '0',
      styleProperty: 'fillOpacity',
    },
    
  },
}
export const PRETTY_BLUE_STYLE_CIRCLE =
  {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeColor: '#FFFFFF',
    strokeOpacity: 2,
    strokeWeight: 3,
    fillColor: '#00B2FF',
    fillOpacity: 1,
    radius: 5, 
  }

export const PRETTY_BLUE_STYLE_POLYGON =
{
  zIndex: mapOverlayZIndex.mapLayer,
  strokeColor: '#00B2FF',
  strokeOpacity: 1,
  strokeWeight: 5,
  fillColor: '#00B2FF',
  fillOpacity: 0.3,
}

export const PRETTY_BLUE_WHITE_STYLE: PolygonPaintStyle =
{
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#ffffff',
    strokeWeight: 1,
    strokeOpacity: 1,
  },
  mapStyle: {
    type: 'map',
    params: {
      range: ['0.2', '0.4', '0.6', '0.8'],
      default: '0',
      styleProperty: 'fillOpacity',
    },
  },
}

export const PRETTY_BLUE_EV_COUNT: (feature: AllEvOptions) => PolygonPaintStyle = (feature) => {
  return {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#4169e1',
      strokeColor: '#ffffff',
      strokeWeight: 1,
      strokeOpacity: 1,   
    },
    mapStyle: {
      type: 'map',
      params: {
        min: 0,
        max: 1,
        featureProperty: feature,
        range: ['0.2', '0.4', '0.6', '0.8'],
        default: '0',
        styleProperty: 'fillOpacity',
      },
    },
  }
}

export const PRETTY_BLUE_STYLE: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#4169e1',
      strokeColor: '#15224a',
      strokeWeight: 1,
      strokeOpacity: 0.2,
    },
    mapStyle: {
      type: 'map',
      params: {
        range: ['0.2', '0.4', '0.6', '0.8'],
        default: '0',
        styleProperty: 'fillOpacity',
      },
    },
  }

export const PRETTY_SINGLE_STYLE: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#4169e1',
      strokeColor: '#15224a',
      strokeWeight: 1,
      strokeOpacity: 0.2,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          default: '1',
          styleProperty: 'fillOpacity',
        },
      },
    },
  }

export const PRETTY_BLUE_STROKE_DEFAULT_STYLE: PolygonPaintStyle =
{
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#15224a',
    strokeWeight: 1,
    strokeOpacity: 0.2,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        fillColor: '#4169e1',
        strokeColor: '#15224a',
      },
    },
  },
}

export const ALTERNATIVE_FUEL_CORRIDORS: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#4169e1',
      strokeColor: '#15224a',
      strokeWeight: 1,
      strokeOpacity: 0.2,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          1: {
            fillColor: '#4169e1',
            strokeColor: '#15224a',
            fillOpacity: 0.8,
          },
          0: {
            fillColor: '#4169e1',
            strokeColor: '#15224a',
            fillOpacity: 0.2,
          },
        },
      },
    },
  }

export const PRETTY_BLUE_STROKE_STYLE: PolygonPaintStyle =
{
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#4169e1',
    strokeWeight: 1,
    strokeOpacity: 0.6,
  
  },
  mapStyle: {
    type: 'map',
    params: {
      range: ['0.2', '0.4', '0.6', '0.8'],
      default: '0',
      styleProperty: 'fillOpacity',
    },
  },
}

export const REGULAR_STYLE: PolygonPaintStyle =
{
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    strokeColor: '#1b7d8e',
    strokeWeight: 0.2,
  },
  mapStyle: {
    type: 'map',
    params: {
      range: [
        '#fa0000', '#f75500', '#eb8000', '#d5a500', '#b5c600', '#86e400', '#00ff00',
      ],
      default: '#000',
      styleProperty: 'fillColor',
    },
  },
}


export const OFFSTREET_PARKING: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillOpacity: 0.3,
      strokeWeight: 0.9,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          'mainly_non_residential_area': {
            fillColor: '#000000',
            strokeColor: '#000000',
          },
          'low_probability': {
            fillColor: '#fa0000',
            strokeColor: '#fa0000',
          },
          'mixed': {
            fillColor: '#ffd700',
            strokeColor: '#ffd700',
          },
          'high_probability': {
            fillColor: '#00ff00',
            strokeColor: '#00ff00',
          },
        },
      },
    },
  }

export const OFFSTREET_PARKING_RESIDENTIAL: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillOpacity: 0.3,
      strokeWeight: 0.9,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          'high_probability': {
            fillColor: '#fa0000',
            strokeColor: '#fa0000',
          },
          'mixed': {
            fillColor: '#ffd700',
            strokeColor: '#ffd700',
          },
          'low_probability': {
            fillColor: '#00ff00',
            strokeColor: '#00ff00',
          },
        },
      },
    },
  }

export const OFFSTREET_PARKING_PER_HOUSE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillOpacity: 0.3,
    strokeWeight: 0.9,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        'non_residential': {
          fillColor: '#000000',
          strokeColor: '#000000',
        },
        'low_probability': {
          fillColor: '#fa0000',
          strokeColor: '#fa0000',
        },
        'high_probability': {
          fillColor: '#00ff00',
          strokeColor: '#00ff00',
        },
      },
    },
  },
}

export const POI_CLUSTERS_V1: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillOpacity: 0.3,
    strokeWeight: 0.9,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        'education': {
          'fillColor': '#1E90FF',
          'strokeColor': '#1E90FF',
        },
        'sport': {
          'fillColor': '#FF4500',
          'strokeColor': '#FF4500',
        },
        'health': {
          'fillColor': '#32CD32',
          'strokeColor': '#32CD32',
        },
        'culture, historic, attraction': {
          'fillColor': '#FFD700',
          'strokeColor': '#FFD700',
        },
        'accomodation: nature, adventure': {
          'fillColor': '#228B22',
          'strokeColor': '#228B22',
        },
        'mixed: food&drink, nightlife, community oriented, everyday': {
          'fillColor': '#FF69B4',
          'strokeColor': '#FF69B4',
        },
        'mixed; shop oriented, food&drink, everyday': {
          'fillColor': '#FF6347',
          'strokeColor': '#FF6347',
        },
        'mixed: significant parking, outdoor, family friendly, not so shop oriented': {
          'fillColor': '#9370DB',
          'strokeColor': '#9370DB',
        },
        'accomodation: hotels, resorts': {
          'fillColor': '#20B2AA',
          'strokeColor': '#20B2AA',
        },
        'attractions': {
          'fillColor': '#FF8C00',
          'strokeColor': '#FF8C00',
        },
      },
    },
  },
}

export const POI_CLUSTERS_V2: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillOpacity: 0.3,
    strokeWeight: 0.9,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        'education': {
          'fillColor': '#1E90FF',
          'strokeColor': '#1E90FF',
        },
        'sport': {
          'fillColor': '#FF4500',
          'strokeColor': '#FF4500',
        },
        'health': {
          'fillColor': '#32CD32',
          'strokeColor': '#32CD32',
        },
        'culture, historic, attraction': {
          'fillColor': '#FFD700',
          'strokeColor': '#FFD700',
        },
        'accomodation': {
          'fillColor': '#228B22',
          'strokeColor': '#228B22',
        },
        'mixed: residential, small shops and amenities': {
          'fillColor': '#FF69B4',
          'strokeColor': '#FF69B4',
        },
        'mixed: shops, retail, town centres': {
          'fillColor': '#FF6347',
          'strokeColor': '#FF6347',
        },
        'mixed: not so shop oriented, with parking, nightlife, accommodation': {
          'fillColor': '#9370DB',
          'strokeColor': '#9370DB',
        },
        'attraction': {
          'fillColor': '#FF8C00',
          'strokeColor': '#FF8C00',
        },
      },
    },
  },
}

export const URBAN_FOX_PLYMOUTH_LSOAS: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillOpacity: 0.3,
    strokeWeight: 0.9,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        'A': {
          'fillColor': '#ed840c',
          'strokeColor': '#ed840c',
        },
        'B': {
          'fillColor': '#0c88ed',
          'strokeColor': '#0c88ed',
        },
      },
    },
  },
}

const yellow = {
  fillOpacity: 0.5,
  fillColor: '#EDFF19',
  strokeColor: '#EDFF19',
}
const green = {
  fillOpacity: 0.7,
  fillColor: '#4CC54A',
  strokeColor: '#4CC54A',
}
const darkGreen = {
  fillOpacity: 0.5,
  fillColor: '#1F941D',
  strokeColor: '#1F941D',
}
export const TAX_CREDIT_STYLE: PolygonPaintStyle = {
  rendererProp: {
    zIndex: mapOverlayZIndex.mapLayer,
    fillColor: '#4169e1',
    strokeColor: '#15224a',
    strokeWeight: 1,
    strokeOpacity: 0.2,
  },
  mapStyle: {
    type: 'map',
    params: {
      map: {
        1: yellow,
        2: green,
        4: darkGreen,

        // Layers with overlapping areas get the color of the highest-year layer
        3: green,
        5: darkGreen,
        7: darkGreen,
      },
      mapKeyOverride: {
        '2024 (2011-2015 NMTC tracts)': yellow,
        '2029 (2016-2020 NMTC tracts)': green,
        '2030 (2020 Non-Urban tracts)': darkGreen,
      },
    },
  },
}

/**
 * CK custom styles
 */
export const CK_HIGHWAY_BOUNDARY_STYLE: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#ff34ff',
      strokeColor: '#ff34ff',
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          default: '1',
          styleProperty: 'fillOpacity',
        },
      },
    },
  }

export const CK_PARKING_RESTRICTIONS_STYLE: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#f5d159',
      strokeColor: '#efdb0c',
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          default: '1',
          styleProperty: 'fillOpacity',
        },
      },
    },
  }

export const CK_PROPOSED_USRN_STYLE: PolygonPaintStyle =
  {
    rendererProp: {
      zIndex: mapOverlayZIndex.mapLayer,
      fillColor: '#65188c',
      strokeColor: '#65188c',
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    mapStyle: {
      type: 'map',
      params: {
        map: {
          default: '1',
          styleProperty: 'fillOpacity',
        },
      },
    },
  }
